import axios, { AxiosInstance } from 'axios'
import { action, computed, observable } from 'mobx'
import { ConfigStore } from 'core'
import {is24Betting} from '../../utils/24betting'

// TODO: remove this timeout when i18n will be stable and never fall out
const I18N_REQUEST_TIMEOUT = 5000
const I18N_REQUEST_URL = 'https://i18n.bettings.ch'

export class HttpStore {
  wst: AxiosInstance
  i18n: AxiosInstance
  is24Betting: boolean

  constructor(private config: ConfigStore) {
    const { domain } = this.config.data
    this.wst = axios.create({
      baseURL: `${this.config.data.https ? 'https' : 'http'}://${domain}/api/v1`,
      withCredentials: true,
    })
    this.is24Betting = is24Betting(config)
    this.i18n = axios.create({
      baseURL: I18N_REQUEST_URL,
      timeout: I18N_REQUEST_TIMEOUT,
    })
  }

  @observable selfExchangeSource: string

  @computed
  get bts() {
    if (!this.config.data.sportsbook) return axios
    const { url, token } = this.config.data.sportsbook
    const params: any = { _token: token }

    if (window.localStorage.getItem('experimentalLineTranslations') === 'true') {
      const locale = window.localStorage.getItem('locale') || 'en_US'

      const lang = locale.substr(0, 2)
      params.lang = lang
    }
    const ax = axios.create({
      baseURL: `${url}/v3`,
      params,
    })

    return ax
  }

  @computed
  get sbstl() {
    if (!this.config.data.sportsbook) return axios
    const { token, sbstlapi } = this.config.data.sportsbook
    const ax = axios.create({
      baseURL: sbstlapi,
      params: { _token: token },
    })

    return ax
  }

  @computed
  get matchbook() {
    if (!this.config.data.matchbook) return axios
    const { url, token } = this.config.data.matchbook

    const ax = axios.create({
      baseURL: url,
      params: { _token: token },
    })

    return ax
  }

  @computed
  get exchange() {
    if (!this.config.data.selfexchange) return axios
    const { url, token } = this.config.data.selfexchange

    const ax = axios.create({
      baseURL: `${url}/${this.selfExchangeSource}`,
      params: { _token: token },
    })

    return ax
  }

  @action.bound
  setSelfExchangeSource(source: string) {
    this.selfExchangeSource = source
  }

  @computed
  get betfair() {
    if (!this.config.data.betfair) return axios
    const { url, accountId } = this.config.data.betfair

    const ax = axios.create({
      baseURL: url,
      params: { account_id: accountId },
    })

    return ax
  }

  @computed
  get sessionGames() {
    const { sessionGames } = this.config.data
    if (!sessionGames) return axios
    const { url, token } = sessionGames

    const ax = axios.create({
      baseURL: url,
      params: { _token: token },
    })
    return ax
  }

  @computed
  get dabba() {
    if (!this.config.data.dabba) return axios
    const { url, accountId } = this.config.data.dabba

    return axios.create({
      baseURL: url,
      params: { account_id: accountId },
    })
  }

  @computed
  get sportsbookLink() {
    if (!this.config.data.sportsbook) return axios
    const { url, token } = this.config.data.sportsbook
    const params: any = { _token: token }

    if (window.localStorage.getItem('experimentalLineTranslations') === 'true') {
      const locale = window.localStorage.getItem('locale') || 'en_US'

      const lang = locale.substr(0, 2)
      params.lang = lang
    }
    const ax = axios.create({
      baseURL: `${url}/1`,
      params,
    })

    return ax
  }

  @computed
  get exchangeLink() {
    if (!this.config.data.sportsbook) return axios
    const { url, token } = this.config.data.sportsbook
    const params: any = { _token: token }

    if (window.localStorage.getItem('experimentalLineTranslations') === 'true') {
      const locale = window.localStorage.getItem('locale') || 'en_US'

      const lang = locale.substr(0, 2)
      params.lang = lang
    }
    const ax = axios.create({
      baseURL: `${url}/v2/1`,
      params,
    })

    return ax
  }

  @computed
  get exchangeCreate() {
    if (!this.config.data.sportsbook) return axios
    const { url } = this.config.data.sportsbook

    if (window.localStorage.getItem('experimentalLineTranslations') === 'true') {
    }
    const ax = axios.create({
      baseURL: `${url}`,
    })

    return ax
  }

  @computed
  get sportsbook2() {
    if (!this.config.data.sportsbook) return axios
    const { url, token } = this.config.data.sportsbook
    const source = this.config.front['sportsbook.sources'][0].live.source
    const params: any = { _token: token }

    if (window.localStorage.getItem('experimentalLineTranslations') === 'true') {
      const locale = window.localStorage.getItem('locale') || 'en_US'

      const lang = locale.substr(0, 2)
      params.lang = lang
    }

    const ax = axios.create({
      baseURL: `${url}/v2/${source}/`,
      params,
    })

    return ax
  }

  onWstUnauthorized(cb: Function) {
    this.wst.interceptors.response.use(
      config => config,
      error => {
        if (error.response.status === 401) {
          cb()
        }

        return Promise.reject(error)
      }
    )
  }
}
